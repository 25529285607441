<template>
  <nuxt-link :to="routerLink" class="card-category" :class="classes">
    <div class="card-category__border-overlay" />
    <div class="card-category__bg-overlay" />
    <atomic-icon v-if="categoryContent?.icon" :id="categoryContent?.icon" filled />
    <div class="card-category__label">{{ categoryContent?.label || props.categoryData.name }}</div>
  </nuxt-link>
</template>

<script setup lang="ts">
  import type { CICategory, ICollection } from '~/types';

  const props = defineProps<{
    categoryData: ICollection;
    type: 'transparent' | 'filled';
    accent?: boolean;
  }>();

  const { getContent, localizePath } = useProjectMethods();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();

  const routerLink = computed(() => {
    if (['favorites', 'recently'].includes(props.categoryData.identity)) {
      return localizePath(`/${props.categoryData.identity}`);
    }

    return localizePath(`/${sectionName}/${props.categoryData.identity}`);
  });

  const categoryContent = computed(() => {
    if (props.categoryData.identity === 'recently') {
      return {
        icon: getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.recently.icon'),
        label: getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.recently.label'),
        identity: 'recently',
      };
    }

    if (props.categoryData.identity === 'favorites') {
      return {
        icon: getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.favorites.icon'),
        label: getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.favorites.label'),
        identity: 'favorites',
      };
    }

    const contentCategories: CICategory[] =
      getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.categoriesList') || [];
    return contentCategories.find(category => category.identity === props.categoryData.identity);
  });

  const route = useRoute();
  const routeName = route.name as string;
  const sectionName =
    routeName.startsWith('live-casino') || routeName.startsWith('locale-live-casino') ? 'live-casino' : 'casino';

  const classes = [`card-category--${props.type || 'transparent'}`, { 'card-category--accent': props.accent }];
</script>

<style src="~/assets/styles/components/card/category.scss" lang="scss" />
